var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box d-flex flex-column flex-1" }, [
    !_vm.isMobile
      ? _c(
          "div",
          { staticClass: "mainPC flex-1", staticStyle: { width: "1250px" } },
          [
            _c("img", {
              staticClass: "bg_activity",
              attrs: {
                src: require("../../assets/imgs/activity/20240214/bg_pc_top.jpg"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "rankingBox" }, [
                _c("div", { staticClass: "topBox" }, [
                  _vm.rankList[0]
                    ? _c("div", { staticClass: "top1Box d-flex j-center" }, [
                        _c("div", { staticClass: "left" }, [
                          _c(
                            "div",
                            { staticClass: "imgBox" },
                            [
                              _vm.rankList[0].svgaUrlA
                                ? _c("svgaCom", {
                                    staticClass: "svga",
                                    attrs: {
                                      loop: 1,
                                      svgaUrl: _vm.rankList[0].svgaUrlA,
                                      id: `activitySvga_top1_A`,
                                    },
                                  })
                                : _vm._e(),
                              _c("img", {
                                staticClass: "imgUrl",
                                attrs: {
                                  src: _vm.rankList[0].imgUrlA,
                                  alt: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.rankList[0].nickNameA)),
                          ]),
                        ]),
                        _c("div", { staticClass: "right" }, [
                          _c(
                            "div",
                            { staticClass: "imgBox" },
                            [
                              _vm.rankList[0].svgaUrlB
                                ? _c("svgaCom", {
                                    staticClass: "svga",
                                    attrs: {
                                      loop: 1,
                                      svgaUrl: _vm.rankList[0].svgaUrlB,
                                      id: `activitySvga_top1_B`,
                                    },
                                  })
                                : _vm._e(),
                              _c("img", {
                                staticClass: "imgUrl",
                                attrs: {
                                  src: _vm.rankList[0].imgUrlB,
                                  alt: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.rankList[0].nickNameB)),
                          ]),
                        ]),
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            "情缘值：" + _vm._s(_vm.rankList[0].totalAmount)
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.rankList[1]
                    ? _c("div", { staticClass: "top2Box d-flex j-center" }, [
                        _c("div", { staticClass: "left" }, [
                          _c(
                            "div",
                            { staticClass: "imgBox" },
                            [
                              _vm.rankList[1].svgaUrlA
                                ? _c("svgaCom", {
                                    staticClass: "svga",
                                    attrs: {
                                      loop: 1,
                                      svgaUrl: _vm.rankList[1].svgaUrlA,
                                      id: `activitySvga_top2_A`,
                                    },
                                  })
                                : _vm._e(),
                              _c("img", {
                                staticClass: "imgUrl",
                                attrs: {
                                  src: _vm.rankList[1].imgUrlA,
                                  alt: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.rankList[1].nickNameA)),
                          ]),
                        ]),
                        _c("div", { staticClass: "right" }, [
                          _c(
                            "div",
                            { staticClass: "imgBox" },
                            [
                              _vm.rankList[1].svgaUrlB
                                ? _c("svgaCom", {
                                    staticClass: "svga",
                                    attrs: {
                                      loop: 1,
                                      svgaUrl: _vm.rankList[1].svgaUrlB,
                                      id: `activitySvga_top2_B`,
                                    },
                                  })
                                : _vm._e(),
                              _c("img", {
                                staticClass: "imgUrl",
                                attrs: {
                                  src: _vm.rankList[1].imgUrlB,
                                  alt: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.rankList[1].nickNameB)),
                          ]),
                        ]),
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            "情缘值：" + _vm._s(_vm.rankList[1].totalAmount)
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.rankList[2]
                    ? _c("div", { staticClass: "top3Box d-flex j-center" }, [
                        _c("div", { staticClass: "left" }, [
                          _c(
                            "div",
                            { staticClass: "imgBox" },
                            [
                              _vm.rankList[2].svgaUrlA
                                ? _c("svgaCom", {
                                    staticClass: "svga",
                                    attrs: {
                                      loop: 1,
                                      svgaUrl: _vm.rankList[2].svgaUrlA,
                                      id: `activitySvga_top3_A`,
                                    },
                                  })
                                : _vm._e(),
                              _c("img", {
                                staticClass: "imgUrl",
                                attrs: {
                                  src: _vm.rankList[2].imgUrlA,
                                  alt: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.rankList[2].nickNameA)),
                          ]),
                        ]),
                        _c("div", { staticClass: "right" }, [
                          _c(
                            "div",
                            { staticClass: "imgBox" },
                            [
                              _vm.rankList[2].svgaUrlB
                                ? _c("svgaCom", {
                                    staticClass: "svga",
                                    attrs: {
                                      loop: 1,
                                      svgaUrl: _vm.rankList[2].svgaUrlB,
                                      id: `activitySvga_top3_B`,
                                    },
                                  })
                                : _vm._e(),
                              _c("img", {
                                staticClass: "imgUrl",
                                attrs: {
                                  src: _vm.rankList[2].imgUrlB,
                                  alt: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.rankList[2].nickNameB)),
                          ]),
                        ]),
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            "情缘值：" + _vm._s(_vm.rankList[2].totalAmount)
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "list" }, [
                  _vm.rankList.length > 3
                    ? _c(
                        "div",
                        _vm._l(_vm.rankList, function (item, index) {
                          return _c("div", { key: index }, [
                            index >= 3
                              ? _c(
                                  "div",
                                  { staticClass: "item d-flex a-center" },
                                  [
                                    _c("div", { staticClass: "sortNum" }, [
                                      _vm._v("第" + _vm._s(index + 1) + "名"),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "imgBox d-flex a-center" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "left d-flex a-center j-center",
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "imgUrl",
                                              attrs: {
                                                src: item.imgUrlA,
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "right d-flex a-center j-center",
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "imgUrl",
                                              attrs: {
                                                src: item.imgUrlB,
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("img", {
                                          staticClass: "iconLove",
                                          attrs: {
                                            src: require("../../assets/imgs/activity/20240214/icon_love.png"),
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "nameBox flex-1 d-overflow",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "name d-overflow" },
                                          [
                                            _vm._v(
                                              "昵称：" + _vm._s(item.nickNameA)
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "name d-overflow" },
                                          [
                                            _vm._v(
                                              "昵称：" + _vm._s(item.nickNameB)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "numBox" }, [
                                      _vm._v(
                                        "情缘值：" + _vm._s(item.totalAmount)
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.myRankInfo
                    ? _c(
                        "div",
                        { staticClass: "item d-flex a-center active" },
                        [
                          _c("div", { staticClass: "sortNum" }, [
                            _vm._v(
                              "第" + _vm._s(_vm.myRankInfo.rankNum) + "名"
                            ),
                          ]),
                          _c("div", { staticClass: "imgBox d-flex a-center" }, [
                            _c(
                              "div",
                              { staticClass: "left d-flex a-center j-center" },
                              [
                                _c("img", {
                                  staticClass: "imgUrl",
                                  attrs: {
                                    src: _vm.myRankInfo.imgUrlA,
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "right d-flex a-center j-center" },
                              [
                                _c("img", {
                                  staticClass: "imgUrl",
                                  attrs: {
                                    src: _vm.myRankInfo.imgUrlB,
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                            _c("img", {
                              staticClass: "iconLove",
                              attrs: {
                                src: require("../../assets/imgs/activity/20240214/icon_love.png"),
                                alt: "",
                              },
                            }),
                          ]),
                          _c(
                            "div",
                            { staticClass: "nameBox flex-1 d-overflow" },
                            [
                              _c("div", { staticClass: "name d-overflow" }, [
                                _vm._v(
                                  "昵称：" + _vm._s(_vm.myRankInfo.nickNameA)
                                ),
                              ]),
                              _c("div", { staticClass: "name d-overflow" }, [
                                _vm._v(
                                  "昵称：" + _vm._s(_vm.myRankInfo.nickNameB)
                                ),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "numBox" }, [
                            _vm._v(
                              "情缘值：" + _vm._s(_vm.myRankInfo.totalAmount)
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "rewardBox" },
                _vm._l(_vm.giftList, function (item, index) {
                  return _c("div", { key: index, staticClass: "reward" }, [
                    _c("div", {
                      class: [
                        "iconTitle",
                        { iconTitle1: index == 0 },
                        { iconTitle2: index == 1 },
                        { iconTitle3: index == 2 },
                        { iconTitle410: index == 3 },
                      ],
                    }),
                    _c(
                      "div",
                      { staticClass: "gift d-flex a-center j-center" },
                      _vm._l(item, function (goods, gIndex) {
                        return _c(
                          "div",
                          { key: gIndex, staticClass: "goods" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "imgBox d-flex a-center j-center",
                              },
                              [
                                goods.svgaUrl &&
                                _vm.endsWithSuffixAlternative(
                                  goods.svgaUrl,
                                  ".svga"
                                )
                                  ? _c("svgaCom", {
                                      staticClass: "imgUrl",
                                      attrs: {
                                        loop: 1,
                                        svgaUrl: goods.svgaUrl,
                                        id: `activitySvga_${index}_${gIndex}`,
                                      },
                                    })
                                  : _c("img", {
                                      staticClass: "imgUrl",
                                      attrs: { src: goods.svgaUrl, alt: "" },
                                    }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(goods.name)),
                            ]),
                            goods.day > 0
                              ? _c("div", { staticClass: "day" }, [
                                  _vm._v(_vm._s(goods.day) + "天"),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                }),
                0
              ),
              _vm._m(0),
              _vm._m(1),
            ]),
          ]
        )
      : _vm._e(),
    _vm.isMobile
      ? _c("div", { staticClass: "main" }, [
          _c("img", {
            staticClass: "bg_activity",
            attrs: {
              src: require("../../assets/imgs/activity/20240214/bg_h5_top.png"),
              alt: "",
            },
          }),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "rankingBox" }, [
              _c("div", { staticClass: "topBox" }, [
                _vm.rankList[0]
                  ? _c("div", { staticClass: "top1Box d-flex j-center" }, [
                      _c("div", { staticClass: "left" }, [
                        _c(
                          "div",
                          { staticClass: "imgBox" },
                          [
                            _vm.rankList[0].svgaUrlA
                              ? _c("svgaCom", {
                                  staticClass: "svga",
                                  attrs: {
                                    loop: 1,
                                    svgaUrl: _vm.rankList[0].svgaUrlA,
                                    id: `activitySvga_top1_A`,
                                  },
                                })
                              : _vm._e(),
                            _c("img", {
                              staticClass: "imgUrl",
                              attrs: { src: _vm.rankList[0].imgUrlA, alt: "" },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.rankList[0].nickNameA)),
                        ]),
                      ]),
                      _c("div", { staticClass: "right" }, [
                        _c(
                          "div",
                          { staticClass: "imgBox" },
                          [
                            _vm.rankList[0].svgaUrlB
                              ? _c("svgaCom", {
                                  staticClass: "svga",
                                  attrs: {
                                    loop: 1,
                                    svgaUrl: _vm.rankList[0].svgaUrlB,
                                    id: `activitySvga_top1_B`,
                                  },
                                })
                              : _vm._e(),
                            _c("img", {
                              staticClass: "imgUrl",
                              attrs: { src: _vm.rankList[0].imgUrlB, alt: "" },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.rankList[0].nickNameB)),
                        ]),
                      ]),
                      _c("div", { staticClass: "num" }, [
                        _vm._v(
                          "情缘值：" + _vm._s(_vm.rankList[0].totalAmount)
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.rankList[1]
                  ? _c("div", { staticClass: "top2Box d-flex j-center" }, [
                      _c("div", { staticClass: "left" }, [
                        _c(
                          "div",
                          { staticClass: "imgBox" },
                          [
                            _vm.rankList[1].svgaUrlA
                              ? _c("svgaCom", {
                                  staticClass: "svga",
                                  attrs: {
                                    loop: 1,
                                    svgaUrl: _vm.rankList[1].svgaUrlA,
                                    id: `activitySvga_top2_A`,
                                  },
                                })
                              : _vm._e(),
                            _c("img", {
                              staticClass: "imgUrl",
                              attrs: { src: _vm.rankList[1].imgUrlA, alt: "" },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.rankList[1].nickNameA)),
                        ]),
                      ]),
                      _c("div", { staticClass: "right" }, [
                        _c(
                          "div",
                          { staticClass: "imgBox" },
                          [
                            _vm.rankList[1].svgaUrlB
                              ? _c("svgaCom", {
                                  staticClass: "svga",
                                  attrs: {
                                    loop: 1,
                                    svgaUrl: _vm.rankList[1].svgaUrlB,
                                    id: `activitySvga_top2_B`,
                                  },
                                })
                              : _vm._e(),
                            _c("img", {
                              staticClass: "imgUrl",
                              attrs: { src: _vm.rankList[1].imgUrlB, alt: "" },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.rankList[1].nickNameB)),
                        ]),
                      ]),
                      _c("div", { staticClass: "num" }, [
                        _vm._v(
                          "情缘值：" + _vm._s(_vm.rankList[1].totalAmount)
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.rankList[2]
                  ? _c("div", { staticClass: "top3Box d-flex j-center" }, [
                      _c("div", { staticClass: "left" }, [
                        _c(
                          "div",
                          { staticClass: "imgBox" },
                          [
                            _vm.rankList[2].svgaUrlA
                              ? _c("svgaCom", {
                                  staticClass: "svga",
                                  attrs: {
                                    loop: 1,
                                    svgaUrl: _vm.rankList[2].svgaUrlA,
                                    id: `activitySvga_top3_A`,
                                  },
                                })
                              : _vm._e(),
                            _c("img", {
                              staticClass: "imgUrl",
                              attrs: { src: _vm.rankList[2].imgUrlA, alt: "" },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.rankList[2].nickNameA)),
                        ]),
                      ]),
                      _c("div", { staticClass: "right" }, [
                        _c(
                          "div",
                          { staticClass: "imgBox" },
                          [
                            _vm.rankList[2].svgaUrlB
                              ? _c("svgaCom", {
                                  staticClass: "svga",
                                  attrs: {
                                    loop: 1,
                                    svgaUrl: _vm.rankList[2].svgaUrlB,
                                    id: `activitySvga_top3_B`,
                                  },
                                })
                              : _vm._e(),
                            _c("img", {
                              staticClass: "imgUrl",
                              attrs: { src: _vm.rankList[2].imgUrlB, alt: "" },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.rankList[2].nickNameB)),
                        ]),
                      ]),
                      _c("div", { staticClass: "num" }, [
                        _vm._v(
                          "情缘值：" + _vm._s(_vm.rankList[2].totalAmount)
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "list" }, [
                _vm.rankList.length > 3
                  ? _c(
                      "div",
                      _vm._l(_vm.rankList, function (item, index) {
                        return _c("div", { key: index }, [
                          index >= 3
                            ? _c(
                                "div",
                                { staticClass: "item d-flex a-center" },
                                [
                                  _c("div", { staticClass: "sortNum" }, [
                                    _vm._v("第" + _vm._s(index + 1) + "名"),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "imgBox d-flex a-center" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "left d-flex a-center j-center",
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "imgUrl",
                                            attrs: {
                                              src: item.imgUrlA,
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "right d-flex a-center j-center",
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "imgUrl",
                                            attrs: {
                                              src: item.imgUrlB,
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                      _c("img", {
                                        staticClass: "iconLove",
                                        attrs: {
                                          src: require("../../assets/imgs/activity/20240214/icon_love.png"),
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c("div", { staticClass: "nameBox flex-1" }, [
                                    _c(
                                      "div",
                                      { staticClass: "name d-overflow" },
                                      [_vm._v(_vm._s(item.nickNameA))]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "name d-overflow" },
                                      [_vm._v(_vm._s(item.nickNameB))]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "numBox" }, [
                                    _vm._v(
                                      "情缘值：" + _vm._s(item.totalAmount)
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _vm.myRankInfo
                  ? _c("div", { staticClass: "item d-flex a-center active" }, [
                      _c("div", { staticClass: "sortNum" }, [
                        _vm._v("第" + _vm._s(_vm.myRankInfo.rankNum) + "名"),
                      ]),
                      _c("div", { staticClass: "imgBox d-flex a-center" }, [
                        _c(
                          "div",
                          { staticClass: "left d-flex a-center j-center" },
                          [
                            _c("img", {
                              staticClass: "imgUrl",
                              attrs: { src: _vm.myRankInfo.imgUrlA, alt: "" },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "right d-flex a-center j-center" },
                          [
                            _c("img", {
                              staticClass: "imgUrl",
                              attrs: { src: _vm.myRankInfo.imgUrlB, alt: "" },
                            }),
                          ]
                        ),
                        _c("img", {
                          staticClass: "iconLove",
                          attrs: {
                            src: require("../../assets/imgs/activity/20240214/icon_love.png"),
                            alt: "",
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "nameBox flex-1" }, [
                        _c("div", { staticClass: "name d-overflow" }, [
                          _vm._v(_vm._s(_vm.myRankInfo.nickNameA)),
                        ]),
                        _c("div", { staticClass: "name d-overflow" }, [
                          _vm._v(_vm._s(_vm.myRankInfo.nickNameB)),
                        ]),
                      ]),
                      _c("div", { staticClass: "numBox" }, [
                        _vm._v("情缘值：" + _vm._s(_vm.myRankInfo.totalAmount)),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "rewardBox" },
              _vm._l(_vm.giftList, function (item, index) {
                return _c("div", { key: index, staticClass: "reward" }, [
                  _c("div", {
                    class: [
                      "iconTitle",
                      { iconTitle1: index == 0 },
                      { iconTitle2: index == 1 },
                      { iconTitle3: index == 2 },
                      { iconTitle410: index == 3 },
                    ],
                  }),
                  _c(
                    "div",
                    { staticClass: "gift d-flex a-center j-center" },
                    _vm._l(item, function (goods, gIndex) {
                      return _c("div", { key: gIndex, staticClass: "goods" }, [
                        _c(
                          "div",
                          { staticClass: "imgBox d-flex a-center j-center" },
                          [
                            goods.svgaUrl &&
                            _vm.endsWithSuffixAlternative(
                              goods.svgaUrl,
                              ".svga"
                            )
                              ? _c("svgaCom", {
                                  staticClass: "imgUrl",
                                  attrs: {
                                    loop: 1,
                                    svgaUrl: goods.svgaUrl,
                                    id: `activitySvga_${index}_${gIndex}`,
                                  },
                                })
                              : _c("img", {
                                  staticClass: "imgUrl",
                                  attrs: { src: goods.svgaUrl, alt: "" },
                                }),
                            goods.day > 0
                              ? _c("div", { staticClass: "day" }, [
                                  _vm._v(_vm._s(goods.day) + "天"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(goods.name)),
                        ]),
                      ])
                    }),
                    0
                  ),
                ])
              }),
              0
            ),
            _vm._m(2),
            _vm._m(3),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ruleBox" }, [
      _vm._v(" 1、活动时间：2024年2月14日-2月16日；"),
      _c("br"),
      _vm._v(
        " 2、活动期间，用户给 CP 赠送礼物可以获得相应的情缘值，并且情缘值会根据对应的 CP 组合进行累计；"
      ),
      _c("br"),
      _vm._v(" 3、每赠送 1 随心钻的礼物可获得 1 点情缘值；"),
      _c("br"),
      _vm._v(" 4、每个用户最多可以与 3 个人绑定 CP 关系；"),
      _c("br"),
      _vm._v(
        " 5、同一用户最多拥有3组CP组合，并且这 3 组 CP 组合均有资格进入排行榜；"
      ),
      _c("br"),
      _vm._v(" 6、特殊礼物（红玫瑰）的情缘值计算为普通礼物的 3 倍；"),
      _c("br"),
      _vm._v(" 7、CP排行榜会持续展示 14 日；"),
      _c("br"),
      _vm._v(" 8、在活动期间，CP 关系解除后将不参与活动榜单并清空累计情缘值；"),
      _c("br"),
      _vm._v(" 9、奖励会在活动结束后 14 个工作日内发放。"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "descBox" }, [
      _vm._v(
        " 在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与；"
      ),
      _c("br"),
      _vm._v(" 如有疑问请联系客服QQ: 1058127957进行咨询 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ruleBox" }, [
      _vm._v(" 1、活动时间：2024年2月14日-2月16日；"),
      _c("br"),
      _vm._v(
        " 2、活动期间，用户给 CP 赠送礼物可以获得相应的情缘值，并且情缘值会根据对应的 CP 组合进行累计；"
      ),
      _c("br"),
      _vm._v(" 3、每赠送 1 随心钻的礼物可获得 1 点情缘值；"),
      _c("br"),
      _vm._v(" 4、每个用户最多可以与 3 个人绑定 CP 关系；"),
      _c("br"),
      _vm._v(
        " 5、同一用户最多拥有3组CP组合，并且这 3 组 CP 组合均有资格进入排行榜；"
      ),
      _c("br"),
      _vm._v(" 6、特殊礼物（红玫瑰）的情缘值计算为普通礼物的 3 倍；"),
      _c("br"),
      _vm._v(" 7、CP排行榜会持续展示 14 日；"),
      _c("br"),
      _vm._v(" 8、在活动期间，CP 关系解除后将不参与活动榜单并清空累计情缘值；"),
      _c("br"),
      _vm._v(" 9、奖励会在活动结束后 14 个工作日内发放。"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "descBox" }, [
      _vm._v(
        " 在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与；"
      ),
      _c("br"),
      _vm._v(" 如有疑问请联系客服QQ: 1058127957进行咨询 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="box d-flex flex-column flex-1">
        <!-- pc -->
        <div class="mainPC flex-1" style="width: 1250px;" v-if="!isMobile">
            <img class="bg_activity" src="../../assets/imgs/activity/20240214/bg_pc_top.jpg" alt="">
            <div class="content">
                <div class="rankingBox">
                    <div class="topBox">
                        <div class="top1Box d-flex j-center" v-if="rankList[0]">
                            <div class="left">
                                <div class="imgBox">
                                    <svgaCom class="svga" v-if="rankList[0].svgaUrlA" :loop="1"
                                        :svgaUrl="rankList[0].svgaUrlA" :id="`activitySvga_top1_A`" />
                                    <img :src="rankList[0].imgUrlA" alt="" class="imgUrl">
                                </div>
                                <div class="name">{{ rankList[0].nickNameA }}</div>
                            </div>
                            <div class="right">
                                <div class="imgBox">
                                    <svgaCom class="svga" v-if="rankList[0].svgaUrlB" :loop="1"
                                        :svgaUrl="rankList[0].svgaUrlB" :id="`activitySvga_top1_B`" />
                                    <img :src="rankList[0].imgUrlB" alt="" class="imgUrl">
                                </div>
                                <div class="name">{{ rankList[0].nickNameB }}</div>
                            </div>
                            <div class="num">情缘值：{{ rankList[0].totalAmount }}</div>
                        </div>
                        <div class="top2Box d-flex j-center" v-if="rankList[1]">
                            <div class="left">
                                <div class="imgBox">
                                    <svgaCom class="svga" v-if="rankList[1].svgaUrlA" :loop="1"
                                        :svgaUrl="rankList[1].svgaUrlA" :id="`activitySvga_top2_A`" />
                                    <img :src="rankList[1].imgUrlA" alt="" class="imgUrl">
                                </div>
                                <div class="name">{{ rankList[1].nickNameA }}</div>
                            </div>
                            <div class="right">
                                <div class="imgBox">
                                    <svgaCom class="svga" v-if="rankList[1].svgaUrlB" :loop="1"
                                        :svgaUrl="rankList[1].svgaUrlB" :id="`activitySvga_top2_B`" />
                                    <img :src="rankList[1].imgUrlB" alt="" class="imgUrl">
                                </div>
                                <div class="name">{{ rankList[1].nickNameB }}</div>
                            </div>
                            <div class="num">情缘值：{{ rankList[1].totalAmount }}</div>
                        </div>
                        <div class="top3Box d-flex j-center" v-if="rankList[2]">
                            <div class="left">
                                <div class="imgBox">
                                    <svgaCom class="svga" v-if="rankList[2].svgaUrlA" :loop="1"
                                        :svgaUrl="rankList[2].svgaUrlA" :id="`activitySvga_top3_A`" />
                                    <img :src="rankList[2].imgUrlA" alt="" class="imgUrl">
                                </div>
                                <div class="name">{{ rankList[2].nickNameA }}</div>
                            </div>
                            <div class="right">
                                <div class="imgBox">
                                    <svgaCom class="svga" v-if="rankList[2].svgaUrlB" :loop="1"
                                        :svgaUrl="rankList[2].svgaUrlB" :id="`activitySvga_top3_B`" />
                                    <img :src="rankList[2].imgUrlB" alt="" class="imgUrl">
                                </div>
                                <div class="name">{{ rankList[2].nickNameB }}</div>
                            </div>
                            <div class="num">情缘值：{{ rankList[2].totalAmount }}</div>
                        </div>
                    </div>

                    <div class="list">
                        <div v-if="rankList.length > 3">
                            <div v-for="(item, index) in rankList" :key="index">
                                <div v-if="index >= 3" class="item d-flex a-center">
                                    <div class="sortNum">第{{ index + 1 }}名</div>
                                    <div class="imgBox d-flex a-center">
                                        <div class="left d-flex a-center j-center">
                                            <img :src="item.imgUrlA" alt="" class="imgUrl">
                                        </div>
                                        <div class="right d-flex a-center j-center">
                                            <img :src="item.imgUrlB" alt="" class="imgUrl">
                                        </div>
                                        <img src="../../assets/imgs/activity/20240214/icon_love.png" alt=""
                                            class="iconLove">
                                    </div>
                                    <div class="nameBox flex-1 d-overflow">
                                        <div class="name d-overflow">昵称：{{ item.nickNameA }}</div>
                                        <div class="name d-overflow">昵称：{{ item.nickNameB }}</div>
                                    </div>
                                    <div class="numBox">情缘值：{{ item.totalAmount }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="item d-flex a-center active" v-if="myRankInfo">
                            <div class="sortNum">第{{ myRankInfo.rankNum }}名</div>
                            <div class="imgBox d-flex a-center">
                                <div class="left d-flex a-center j-center">
                                    <img :src="myRankInfo.imgUrlA" alt="" class="imgUrl">
                                </div>
                                <div class="right d-flex a-center j-center">
                                    <img :src="myRankInfo.imgUrlB" alt="" class="imgUrl">
                                </div>
                                <img src="../../assets/imgs/activity/20240214/icon_love.png" alt="" class="iconLove">
                            </div>
                            <div class="nameBox flex-1 d-overflow">
                                <div class="name d-overflow">昵称：{{ myRankInfo.nickNameA }}</div>
                                <div class="name d-overflow">昵称：{{ myRankInfo.nickNameB }}</div>
                            </div>
                            <div class="numBox">情缘值：{{ myRankInfo.totalAmount }}</div>
                        </div>
                    </div>
                </div>

                <div class="rewardBox">
                    <div class="reward" v-for="(item, index) in giftList" :key="index">
                        <div
                            :class="['iconTitle', { iconTitle1: index == 0 }, { iconTitle2: index == 1 }, { iconTitle3: index == 2 }, { iconTitle410: index == 3 }]">
                        </div>
                        <div class="gift d-flex a-center j-center">
                            <div class="goods" v-for="(goods, gIndex) in item" :key="gIndex">
                                <div class="imgBox d-flex a-center j-center">
                                    <svgaCom class="imgUrl"
                                        v-if="goods.svgaUrl && endsWithSuffixAlternative(goods.svgaUrl, '.svga')" :loop="1"
                                        :svgaUrl="goods.svgaUrl" :id="`activitySvga_${index}_${gIndex}`" />
                                    <img v-else :src="goods.svgaUrl" alt="" class="imgUrl">
                                </div>
                                <div class="name">{{ goods.name }}</div>
                                <div class="day" v-if="goods.day > 0">{{ goods.day }}天</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ruleBox">
                    1、活动时间：2024年2月14日-2月16日；<br />
                    2、活动期间，用户给 CP 赠送礼物可以获得相应的情缘值，并且情缘值会根据对应的 CP 组合进行累计；<br />
                    3、每赠送 1 随心钻的礼物可获得 1 点情缘值；<br />
                    4、每个用户最多可以与 3 个人绑定 CP 关系；<br />
                    5、同一用户最多拥有3组CP组合，并且这 3 组 CP 组合均有资格进入排行榜；<br />
                    6、特殊礼物（红玫瑰）的情缘值计算为普通礼物的 3 倍；<br />
                    7、CP排行榜会持续展示 14 日；<br />
                    8、在活动期间，CP 关系解除后将不参与活动榜单并清空累计情缘值；<br />
                    9、奖励会在活动结束后 14 个工作日内发放。<br />
                </div>


                <div class="descBox">
                    在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与；<br />
                    如有疑问请联系客服QQ: 1058127957进行咨询
                </div>
            </div>
        </div>

        <!-- app -->
        <div class="main" v-if="isMobile">
            <img class="bg_activity" src="../../assets/imgs/activity/20240214/bg_h5_top.png" alt="">
            <div class="content">
                <div class="rankingBox">
                    <div class="topBox">
                        <div class="top1Box d-flex j-center" v-if="rankList[0]">
                            <div class="left">
                                <div class="imgBox">
                                    <svgaCom class="svga" v-if="rankList[0].svgaUrlA" :loop="1"
                                        :svgaUrl="rankList[0].svgaUrlA" :id="`activitySvga_top1_A`" />
                                    <img :src="rankList[0].imgUrlA" alt="" class="imgUrl">
                                </div>
                                <div class="name">{{ rankList[0].nickNameA }}</div>
                            </div>
                            <div class="right">
                                <div class="imgBox">
                                    <svgaCom class="svga" v-if="rankList[0].svgaUrlB" :loop="1"
                                        :svgaUrl="rankList[0].svgaUrlB" :id="`activitySvga_top1_B`" />
                                    <img :src="rankList[0].imgUrlB" alt="" class="imgUrl">
                                </div>
                                <div class="name">{{ rankList[0].nickNameB }}</div>
                            </div>
                            <div class="num">情缘值：{{ rankList[0].totalAmount }}</div>
                        </div>
                        <div class="top2Box d-flex j-center" v-if="rankList[1]">
                            <div class="left">
                                <div class="imgBox">
                                    <svgaCom class="svga" v-if="rankList[1].svgaUrlA" :loop="1"
                                        :svgaUrl="rankList[1].svgaUrlA" :id="`activitySvga_top2_A`" />
                                    <img :src="rankList[1].imgUrlA" alt="" class="imgUrl">
                                </div>
                                <div class="name">{{ rankList[1].nickNameA }}</div>
                            </div>
                            <div class="right">
                                <div class="imgBox">
                                    <svgaCom class="svga" v-if="rankList[1].svgaUrlB" :loop="1"
                                        :svgaUrl="rankList[1].svgaUrlB" :id="`activitySvga_top2_B`" />
                                    <img :src="rankList[1].imgUrlB" alt="" class="imgUrl">
                                </div>
                                <div class="name">{{ rankList[1].nickNameB }}</div>
                            </div>
                            <div class="num">情缘值：{{ rankList[1].totalAmount }}</div>
                        </div>
                        <div class="top3Box d-flex j-center" v-if="rankList[2]">
                            <div class="left">
                                <div class="imgBox">
                                    <svgaCom class="svga" v-if="rankList[2].svgaUrlA" :loop="1"
                                        :svgaUrl="rankList[2].svgaUrlA" :id="`activitySvga_top3_A`" />
                                    <img :src="rankList[2].imgUrlA" alt="" class="imgUrl">
                                </div>
                                <div class="name">{{ rankList[2].nickNameA }}</div>
                            </div>
                            <div class="right">
                                <div class="imgBox">
                                    <svgaCom class="svga" v-if="rankList[2].svgaUrlB" :loop="1"
                                        :svgaUrl="rankList[2].svgaUrlB" :id="`activitySvga_top3_B`" />
                                    <img :src="rankList[2].imgUrlB" alt="" class="imgUrl">
                                </div>
                                <div class="name">{{ rankList[2].nickNameB }}</div>
                            </div>
                            <div class="num">情缘值：{{ rankList[2].totalAmount }}</div>
                        </div>
                    </div>

                    <div class="list">
                        <div v-if="rankList.length > 3">
                            <div v-for="(item, index) in rankList" :key="index">
                                <div v-if="index >= 3" class="item d-flex a-center">
                                    <div class="sortNum">第{{ index + 1 }}名</div>
                                    <div class="imgBox d-flex a-center">
                                        <div class="left d-flex a-center j-center">
                                            <img :src="item.imgUrlA" alt="" class="imgUrl">
                                        </div>
                                        <div class="right d-flex a-center j-center">
                                            <img :src="item.imgUrlB" alt="" class="imgUrl">
                                        </div>
                                        <img src="../../assets/imgs/activity/20240214/icon_love.png" alt=""
                                            class="iconLove">
                                    </div>
                                    <div class="nameBox flex-1">
                                        <div class="name d-overflow">{{ item.nickNameA }}</div>
                                        <div class="name d-overflow">{{ item.nickNameB }}</div>
                                    </div>
                                    <div class="numBox">情缘值：{{ item.totalAmount }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="item d-flex a-center active" v-if="myRankInfo">
                            <div class="sortNum">第{{ myRankInfo.rankNum }}名</div>
                            <div class="imgBox d-flex a-center">
                                <div class="left d-flex a-center j-center">
                                    <img :src="myRankInfo.imgUrlA" alt="" class="imgUrl">
                                </div>
                                <div class="right d-flex a-center j-center">
                                    <img :src="myRankInfo.imgUrlB" alt="" class="imgUrl">
                                </div>
                                <img src="../../assets/imgs/activity/20240214/icon_love.png" alt="" class="iconLove">
                            </div>
                            <div class="nameBox flex-1">
                                <div class="name d-overflow">{{ myRankInfo.nickNameA }}</div>
                                <div class="name d-overflow">{{ myRankInfo.nickNameB }}</div>
                            </div>
                            <div class="numBox">情缘值：{{ myRankInfo.totalAmount }}</div>
                        </div>
                    </div>
                </div>

                <div class="rewardBox">
                    <div class="reward" v-for="(item, index) in giftList" :key="index">
                        <div
                            :class="['iconTitle', { iconTitle1: index == 0 }, { iconTitle2: index == 1 }, { iconTitle3: index == 2 }, { iconTitle410: index == 3 }]">
                        </div>
                        <div class="gift d-flex a-center j-center">
                            <div class="goods" v-for="(goods, gIndex) in item" :key="gIndex">
                                <div class="imgBox d-flex a-center j-center">
                                    <svgaCom class="imgUrl"
                                        v-if="goods.svgaUrl && endsWithSuffixAlternative(goods.svgaUrl, '.svga')" :loop="1"
                                        :svgaUrl="goods.svgaUrl" :id="`activitySvga_${index}_${gIndex}`" />
                                    <img v-else :src="goods.svgaUrl" alt="" class="imgUrl">
                                    <div class="day" v-if="goods.day > 0">{{ goods.day }}天</div>
                                </div>
                                <div class="name">{{ goods.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ruleBox">
                    1、活动时间：2024年2月14日-2月16日；<br />
                    2、活动期间，用户给 CP 赠送礼物可以获得相应的情缘值，并且情缘值会根据对应的 CP 组合进行累计；<br />
                    3、每赠送 1 随心钻的礼物可获得 1 点情缘值；<br />
                    4、每个用户最多可以与 3 个人绑定 CP 关系；<br />
                    5、同一用户最多拥有3组CP组合，并且这 3 组 CP 组合均有资格进入排行榜；<br />
                    6、特殊礼物（红玫瑰）的情缘值计算为普通礼物的 3 倍；<br />
                    7、CP排行榜会持续展示 14 日；<br />
                    8、在活动期间，CP 关系解除后将不参与活动榜单并清空累计情缘值；<br />
                    9、奖励会在活动结束后 14 个工作日内发放。<br />
                </div>


                <div class="descBox">
                    在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与；<br />
                    如有疑问请联系客服QQ: 1058127957进行咨询
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {
    rankOfValentine,
    giftOfValentine
} from "@/util/api/api.js"
import svgaCom from '@/components/svgaCom.vue'
export default {
    components: {
        svgaCom,
    },
    data() {
        return {
            isMobile: false,// 是否为移动端打开
            rankList: [],// 排行榜列表
            myRankInfo: null,// 我的排行榜信息
            giftList: [],// 礼物列表
        }
    },
    async created() {
        const userAgent = window.navigator.userAgent;
        console.log(userAgent)
        if (/Mobile|Android|iPhone|iPod|iPad|Windows Phone/i.test(userAgent)) {
            // 如果是在移动端打开，则认为当前设备是移动设备
            this.isMobile = true;
        }
        this._rankOfValentine()
        this._giftOfValentine()
    },
    methods: {
        endsWithSuffixAlternative(str, suffix) {
            return str.slice(-suffix.length) === suffix;
        },
        // 获取情人节活动
        async _rankOfValentine() {
            try {
                let res = await rankOfValentine()
                if (res.resultCode == '000000') {
                    if (res.data && res.data.list) {
                        this.rankList = res.data.list
                        this.myRankInfo = res.data.myRankInfo
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        // 获取情人节活动
        async _giftOfValentine() {
            try {
                let res = await giftOfValentine()
                if (res.resultCode == '000000') {
                    if (res.data && res.data.list) {
                        this.giftList = res.data.list
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
}
</script>

<style lang="less" scoped>
.box {
    color: #fff;
    background: #6993E2;
    min-height: 100vh;
    position: relative;
    overflow: hidden;

    // PC端
    .mainPC {
        margin: 0 auto;
        width: 100%;
        position: relative;
        user-select: none;
        box-sizing: content-box;
        z-index: auto;

        .bg_activity {
            width: 1920px;
            display: block;
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            top: 0;
        }

        .content {
            position: relative;
            z-index: 1;

            .rankingBox {
                margin-top: 677px;
                height: 1717px;
                width: 1218px;
                background: url('../../assets/imgs/activity/20240214/bg_card.png')no-repeat center/cover;

                .topBox {
                    position: relative;
                    height: 517px;

                    .top1Box,
                    .top2Box,
                    .top3Box {
                        height: 307px;
                        width: 351px;
                        padding-top: 70px;
                        box-sizing: border-box;

                        .imgBox {
                            position: relative;

                            .svga {
                                position: absolute;
                                height: 150px;
                                width: 150px;
                                top: -17px;
                                left: -17px;
                                display: block;
                                object-fit: contain;
                            }

                            .imgUrl {
                                display: block;
                                height: 116px;
                                width: 116px;
                                border-radius: 50%;
                            }
                        }

                        .name {
                            font-size: 20px;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                            text-align: center;
                            margin-top: 5px;
                        }

                        .num {
                            position: absolute;
                            left: 54px;
                            bottom: 37px;
                            width: 246px;
                            height: 32px;
                            line-height: 32px;
                            text-align: center;
                            background: #8551FD;
                            border-radius: 15px;
                            font-size: 20px;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                        }

                        .right {
                            margin-left: 30px;
                        }
                    }

                    .top1Box {
                        position: absolute;
                        left: 433px;
                        top: 85px;
                        background: url('../../assets/imgs/activity/20240214/bg_NO1.png')no-repeat center/cover;
                    }

                    .top2Box {
                        position: absolute;
                        left: 81px;
                        top: 168px;
                        background: url('../../assets/imgs/activity/20240214/bg_NO2.png')no-repeat center/cover;
                    }

                    .top3Box {
                        position: absolute;
                        left: 779px;
                        top: 168px;
                        background: url('../../assets/imgs/activity/20240214/bg_NO3.png')no-repeat center/cover;
                    }
                }

                .list {
                    margin: 0 auto;
                    width: 1023px;

                    .item {
                        width: 1023px;
                        height: 118px;
                        background: #5E89DB;
                        border-radius: 24px;
                        margin-bottom: 20px;
                        padding: 0 40px;
                        box-sizing: border-box;

                        &.active {
                            box-shadow: inset 0px 0px 30px 0px rgba(255, 255, 255, 0.5);
                        }

                        .sortNum {
                            width: 117px;
                            font-size: 20px;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                        }

                        .imgBox {
                            position: relative;

                            .left {
                                height: 98px;
                                width: 98px;
                                background: url('../../assets/imgs/activity/20240214/icon_man.png')no-repeat center/cover;
                            }

                            .right {
                                height: 98px;
                                width: 98px;
                                background: url('../../assets/imgs/activity/20240214/icon_woman.png')no-repeat center/cover;
                            }

                            .imgUrl {
                                display: block;
                                height: 80px;
                                width: 80px;
                                border-radius: 50%;
                            }

                            .iconLove {
                                display: block;
                                position: absolute;
                                left: 82px;
                                top: 35px;
                                height: 27px;
                                width: 32px;
                            }
                        }

                        .nameBox {
                            margin-left: 20px;

                            .name {
                                font-size: 20px;
                                font-family: PingFangSC, PingFang SC;
                                font-weight: 400;
                                color: #FFFFFF;
                                line-height: 28px;

                                &:first-child {
                                    margin-bottom: 8px;
                                }
                            }
                        }

                        .numBox {
                            font-size: 20px;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                            line-height: 28px;
                        }
                    }
                }
            }

            .rewardBox {
                padding-top: 157px;
                margin-top: 48px;
                height: 1514px;
                width: 1218px;
                background: url('../../assets/imgs/activity/20240214/bg_card1.png')no-repeat center/cover;
                box-sizing: border-box;

                .iconTitle {
                    height: 32px;
                    margin: 0 auto 34px;
                }

                .iconTitle1 {
                    width: 259px;
                    background: url('../../assets/imgs/activity/20240214/icon_title1.png')no-repeat center/cover;
                }

                .iconTitle2 {
                    width: 259px;
                    background: url('../../assets/imgs/activity/20240214/icon_title2.png')no-repeat center/cover;
                }

                .iconTitle3 {
                    width: 259px;
                    background: url('../../assets/imgs/activity/20240214/icon_title3.png')no-repeat center/cover;
                }

                .iconTitle410 {
                    width: 319px;
                    background: url('../../assets/imgs/activity/20240214/icon_title4-10.png')no-repeat center/cover;
                }

                .reward {
                    margin-bottom: 67px;

                    .goods {
                        width: 256px;
                        position: relative;

                        .imgBox {
                            margin: 0 auto;
                            height: 163px;
                            width: 163px;
                            background: url('../../assets/imgs/activity/20240214/bg_gift.png')no-repeat center/cover;

                            .imgUrl {
                                display: block;
                                max-height: 138px;
                                max-width: 138px;
                                object-fit: contain;
                            }
                        }

                        .name {
                            font-size: 20px;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                            line-height: 28px;
                            margin-top: 12px;
                            text-align: center;
                        }

                        .day {
                            position: absolute;
                            top: -2px;
                            right: 45px;
                            width: 44px;
                            height: 24px;
                            line-height: 24px;
                            text-align: center;
                            background: #FFD900;
                            border-radius: 0px 8px 0px 8px;
                            font-size: 14px;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 600;
                            color: #CE5221;
                        }
                    }
                }
            }

            .ruleBox {
                padding: 116px 128px 0 128px;
                margin-top: 54px;
                height: 656px;
                width: 1218px;
                background: url('../../assets/imgs/activity/20240214/bg_card2.png')no-repeat center/cover;
                box-sizing: border-box;
                font-size: 24px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 42px;
            }

            .descBox {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                margin: 0 auto;
                text-align: center;
                padding-top: 30px;
                padding-bottom: 60px;
            }
        }



        .noActivityBox {
            height: 100%;
            width: 1200px;
            margin: 0 auto;

            .mask {
                position: absolute;
                left: 50%;
                top: 0;
                height: 1080px;
                width: 1920px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                background: rgba(0, 0, 0, 0.5);
            }

            .popBox {
                padding-top: 35px;
                width: 405px;
                height: 191px;
                background: #463B50;
                border-radius: 23px;
                box-sizing: border-box;
                position: absolute;
                left: 50%;
                top: 467px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                z-index: 1;

                .icon_shalou {
                    height: 50px;
                    width: 40px;
                    display: block;
                    margin-right: 18px;
                }

                .text {
                    font-size: 24px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }


                .btnConfirm {
                    height: 42px;
                    width: 102px;
                    // background: url(../../assets/imgs/activity/icon_confirm.png)no-repeat center/cover;
                    margin: 35px auto 0;
                    cursor: pointer;
                }
            }

        }
    }

    // 移动端
    .main {
        margin: 0 auto;
        width: 100%;
        position: relative;
        user-select: none;
        box-sizing: content-box;
        z-index: auto;

        .bg_activity {
            width: 100vw;
            display: block;
        }

        .content {
            position: relative;
            z-index: 1;

            .rankingBox {
                margin: -16vw auto 0;
                height: 240vw;
                width: 96vw;
                background: url('../../assets/imgs/activity/20240214/bg_h5_card.png')no-repeat center/cover;

                .topBox {
                    position: relative;
                    height: 85.067vw;

                    .top1Box,
                    .top2Box,
                    .top3Box {
                        height: 37.333vw;
                        width: 42.667vw;
                        padding-top: 8.8vw;
                        box-sizing: border-box;

                        .imgBox {
                            position: relative;

                            .svga {
                                position: absolute;
                                height: 17.067vw;
                                width: 17.067vw;
                                top: -1.467vw;
                                left: -1.467vw;
                                display: block;
                                object-fit: contain;
                            }

                            .imgUrl {
                                display: block;
                                height: 12.8vw;
                                width: 12.8vw;
                                border-radius: 50%;
                            }
                        }

                        .name {
                            font-size: 2.667vw;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                            text-align: center;
                            margin-top: 1.067vw;
                        }

                        .num {
                            position: absolute;
                            left: 6.133vw;
                            bottom: 4.267vw;
                            width: 30vw;
                            height: 4vw;
                            line-height: 4vw;
                            text-align: center;
                            background: #8551FD;
                            border-radius: 4vw;
                            font-size: 2.667vw;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                        }

                        .right {
                            margin-left: 6.4vw;
                        }
                    }

                    .top1Box {
                        position: absolute;
                        left: 26.933vw;
                        top: 10.4vw;
                        background: url('../../assets/imgs/activity/20240214/bg_NO1.png')no-repeat center/cover;
                    }

                    .top2Box {
                        position: absolute;
                        left: 4.267vw;
                        top: 47.733vw;
                        background: url('../../assets/imgs/activity/20240214/bg_NO2.png')no-repeat center/cover;
                    }

                    .top3Box {
                        position: absolute;
                        left: 49.067vw;
                        top: 47.733vw;
                        background: url('../../assets/imgs/activity/20240214/bg_NO3.png')no-repeat center/cover;
                    }
                }

                .list {
                    margin: 4.267vw auto 0;
                    width: 87.467vw;

                    .item {
                        width: 87.467vw;
                        height: 16vw;
                        background: #5E89DB;
                        border-radius: 3.2vw;
                        margin-bottom: 2.133vw;
                        padding: 0 2.133vw;
                        box-sizing: border-box;

                        &.active {
                            box-shadow: inset 0px 0px 2.133vw 0px rgba(255, 255, 255, 0.5);
                        }

                        .sortNum {
                            width: 9.867vw;
                            font-size: 2.4vw;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                        }

                        .imgBox {
                            position: relative;

                            .left {
                                height: 12.267vw;
                                width: 12.267vw;
                                background: url('../../assets/imgs/activity/20240214/icon_man.png')no-repeat center/cover;
                            }

                            .right {
                                height: 12.267vw;
                                width: 12.267vw;
                                background: url('../../assets/imgs/activity/20240214/icon_woman.png')no-repeat center/cover;
                            }

                            .imgUrl {
                                display: block;
                                height: 10.133vw;
                                width: 10.133vw;
                                border-radius: 50%;
                            }

                            .iconLove {
                                display: block;
                                position: absolute;
                                left: 10.133vw;
                                top: 4.267vw;
                                height: 3.733vw;
                                width: 4.267vw;
                            }
                        }

                        .nameBox {
                            margin-left: 1.6vw;

                            .name {
                                font-size: 2.4vw;
                                font-family: PingFangSC, PingFang SC;
                                font-weight: 400;
                                color: #FFFFFF;
                                line-height: 3.467vw;

                                &:first-child {
                                    margin-bottom: 1.6vw;
                                }
                            }
                        }

                        .numBox {
                            font-size: 2.4vw;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                            line-height: 3.467vw;
                        }
                    }
                }
            }

            .rewardBox {
                padding-top: 14.933vw;
                margin: 6.4vw auto 0;
                height: 160.8vw;
                width: 96vw;
                background: url('../../assets/imgs/activity/20240214/bg_h5_card1.png')no-repeat center/cover;
                box-sizing: border-box;

                .iconTitle {
                    height: 3.2vw;
                    margin: 0 auto 2.133vw;
                }

                .iconTitle1 {
                    width: 26.133vw;
                    background: url('../../assets/imgs/activity/20240214/icon_title1.png')no-repeat center/cover;
                }

                .iconTitle2 {
                    width: 26.133vw;
                    background: url('../../assets/imgs/activity/20240214/icon_title2.png')no-repeat center/cover;
                }

                .iconTitle3 {
                    width: 26.133vw;
                    background: url('../../assets/imgs/activity/20240214/icon_title3.png')no-repeat center/cover;
                }

                .iconTitle410 {
                    width: 32vw;
                    background: url('../../assets/imgs/activity/20240214/icon_title4-10.png')no-repeat center/cover;
                }

                .reward {
                    margin-bottom: 8vw;

                    .goods {
                        width: 28vw;

                        .imgBox {
                            position: relative;
                            margin: 0 auto;
                            height: 17.067vw;
                            width: 17.067vw;
                            background: url('../../assets/imgs/activity/20240214/bg_gift.png')no-repeat center/cover;

                            .imgUrl {
                                display: block;
                                max-height: 14.933vw;
                                max-width: 14.933vw;
                                object-fit: contain;
                            }

                            .day {
                                position: absolute;
                                top: -0.267vw;
                                right: -0.267vw;
                                width: 5.867vw;
                                height: 3.2vw;
                                line-height: 3.2vw;
                                text-align: center;
                                background: #FFD900;
                                border-radius: 0px 1.067vw 0px 1.067vw;
                                font-size: 2.133vw;
                                font-family: PingFangSC, PingFang SC;
                                font-weight: 600;
                                color: #CE5221;
                            }
                        }

                        .name {
                            font-size: 2.533vw;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                            height: 3.733vw;
                            line-height: 3.733vw;
                            margin-top: 1.6vw;
                            text-align: center;
                        }

                    }
                }
            }

            .ruleBox {
                padding: 17.067vw 9.067vw 0 9.333vw;
                margin: 6.133vw auto 0;
                height: 90.667vw;
                width: 96.267vw;
                background: url('../../assets/imgs/activity/20240214/bg_h5_card2.png')no-repeat center/cover;
                box-sizing: border-box;
                font-size: 3.2vw;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 4.533vw;
            }

            .descBox {
                width: 77.867vw;
                font-size: 2.667vw;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                margin: 0 auto;
                text-align: center;
                padding-top: 6.133vw;
                padding-bottom: 8vw;
            }
        }



        .noActivityBox {
            height: 100%;
            width: 1200px;
            margin: 0 auto;

            .mask {
                position: absolute;
                left: 50%;
                top: 0;
                height: 1080px;
                width: 1920px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                background: rgba(0, 0, 0, 0.5);
            }

            .popBox {
                padding-top: 35px;
                width: 405px;
                height: 191px;
                background: #463B50;
                border-radius: 23px;
                box-sizing: border-box;
                position: absolute;
                left: 50%;
                top: 467px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                z-index: 1;

                .icon_shalou {
                    height: 50px;
                    width: 40px;
                    display: block;
                    margin-right: 18px;
                }

                .text {
                    font-size: 24px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }


                .btnConfirm {
                    height: 42px;
                    width: 102px;
                    // background: url(../../assets/imgs/activity/icon_confirm.png)no-repeat center/cover;
                    margin: 35px auto 0;
                    cursor: pointer;
                }
            }

        }
    }
}
</style>
